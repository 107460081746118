//
// Widget 26
//

.kt-widget26 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .kt-widget26__content {
    display: flex;
    padding-bottom: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .kt-widget26__number {
      font-weight: 600;
      font-size: 1.8rem;
    }

    .kt-widget26__desc {
      color: kt-base-color(label, 2);
      font-size: 1.1rem;
      font-weight: 400;
      margin-top: 0.55rem;
    }
  }

  .kt-widget26__chart {
    position: relative;

    canvas {
      border-bottom-left-radius: $kt-border-radius;
      border-bottom-right-radius: $kt-border-radius;
    }
  }
}
