//
// Timeline v1
//

.kt-timeline-v1 {
  position: relative;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    width: 5px;
    top: 0;
    margin-top: 0.1rem;
    bottom: 3rem;
    left: 50%;
    margin-left: -2.5px;
    background-color: #e6eaf5;
  }

  &.kt-timeline-v1--center {
    margin: 0 auto;
  }

  .kt-timeline-v1__items {
    padding-bottom: 6rem;
    position: relative;

    .kt-timeline-v1__marker {
      width: 20px;
      height: 5px;
      @include kt-rounded {
        border-radius: $kt-border-radius;
      }
      position: absolute;
      left: 50%;
      margin-left: -10px;
      background-color: #e6eaf5;
    }

    .kt-timeline-v1__item {
      position: relative;
      margin-left: 0;
      width: 50%;
      min-height: 3rem;

      .kt-timeline-v1__item-arrow {
        color: #f7f8fc;
      }

      .kt-timeline-v1__item-circle {
        background: white;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        position: absolute;

        > div {
          border-radius: 50%;
          width: 6px;
          height: 6px;
        }
      }

      .kt-timeline-v1__item-time {
        position: absolute;
        display: inline-block;
        margin-top: -2.14rem;
        font-weight: 700;
        > span {
          padding-left: 5px;
        }
      }

      .kt-timeline-v1__item-content {
        position: relative;

        @include kt-rounded {
          border-radius: $kt-border-radius;
        }
        padding: 2.14rem;
        background-color: #f7f8fc;

        .kt-timeline-v1__item-title {
          font-size: 1.2rem;
          font-weight: 500;

          & + .kt-timeline-v1__item-body {
            margin-top: 1.25rem;
          }
        }

        .kt-timeline-v1__item-actions {
          margin-top: 1.25rem;
        }

        .kt-timeline-v1__item-desc {
          color: kt-base-color(label, 2);
        }
      }

      &.kt-timeline-v1__item--left {
        left: 0;
        padding-right: 2.85rem;
        position: relative;

        .kt-timeline-v1__item-circle {
          right: -10px;
          top: 20px;
        }

        .kt-timeline-v1__item-time {
          right: 3.8rem;
        }

        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          top: 15px;
          left: 100%;
          margin-left: -3rem;
          border-left: solid 10px #f7f8fc;
          border-bottom: solid 17px transparent;
          border-right: solid 17px transparent;
          border-top: solid 17px transparent;
        }
      }

      &.kt-timeline-v1__item--right {
        left: 50%;
        padding-left: 2.86rem;
        top: -3.5rem;

        .kt-timeline-v1__item-circle {
          top: 20px;
          left: -10px;
        }

        .kt-timeline-v1__item-time {
          left: 3.57rem;
        }

        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          top: 15px;
          right: 100%;
          margin-right: -3rem;
          border-right: solid 10px #f7f8fc;
          border-bottom: solid 17px transparent;
          border-left: solid 17px transparent;
          border-top: solid 17px transparent;
        }
      }

      &.kt-timeline-v1__item--first {
        top: 3.5rem;
        & + .kt-timeline-v1__item--right {
          top: -1rem;
        }
      }
    }
  }

  // Justified mode
  &.kt-timeline-v1--justified {
    &:after {
      left: 0;
      bottom: 0;
    }

    .kt-timeline-v1__items {
      position: static;
      margin-bottom: 40px;
      padding: 20px 0;

      .kt-timeline-v1__marker {
        left: 0;
        top: 0;
      }

      .kt-timeline-v1__item {
        width: 100%;
        top: 0 !important;
        left: 0 !important;
        margin-top: 50px !important;
        padding-left: 2.25rem;
        padding-right: 0;
        position: relative;

        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          top: 15px;
          left: 0;
          margin-left: 3px;
          border-right: solid 10px #f7f8fc;
          border-bottom: solid 17px transparent;
          border-left: solid 17px transparent;
          border-top: solid 17px transparent;
        }

        .kt-timeline-v1__item-time {
          position: absolute;
          display: inline-block;
          margin-top: -2.14rem;
        }

        .kt-timeline-v1__item-circle {
          left: -10px;
          top: 20px;
        }

        .kt-timeline-v1__item-time {
          right: 100%;
          left: 3.57rem;
        }

        &.kt-timeline-v1__item--first {
          margin-top: 20px !important;
        }
      }
    }
  }
}

@include kt-media-below(xl) {
  .kt-timeline-v1 {
    &:after {
      left: 0;
      bottom: 0;
    }

    .kt-timeline-v1__items {
      position: static;
      margin-bottom: 40px;
      padding: 20px 0;

      .kt-timeline-v1__marker {
        left: 0;
        top: 0;
      }

      .kt-timeline-v1__item {
        width: 100%;
        left: 0 !important;
        top: 0 !important;
        margin-top: 50px !important;
        padding-left: 2.25rem !important;
        padding-right: 0 !important;
        position: relative;

        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          top: 15px !important;
          left: 0 !important;
          right: auto !important;
          margin-left: 3px !important;
          border-right: solid 10px #f7f8fc !important;
          border-bottom: solid 17px transparent !important;
          border-left: solid 17px transparent !important;
          border-top: solid 17px transparent !important;
        }

        .kt-timeline-v1__item-time {
          position: absolute;
          display: inline-block;
          margin-top: -2.14rem;
        }

        .kt-timeline-v1__item-circle {
          left: -10px;
          top: 20px;
        }

        .kt-timeline-v1__item-time {
          right: 100%;
          left: 3.57rem;
        }

        &.kt-timeline-v1__item--first {
          margin-top: 20px !important;
        }
      }
    }
  }
}
