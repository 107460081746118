//
// Dropdown
//

$kt-dropdown-menu-widths: (
  xs: 160px,
  sm: 240px,
  md: 260px,
  lg: 320px,
  xl: 380px
);

// Base
.dropdown-menu {
  border: 0 !important;
  margin: 0;
  border-radius: 0;
  min-width: 14rem;
  padding: 0;
  box-shadow: $dropdown-box-shadow;
  padding: 1rem 0;

  // Fit options
  &.dropdown-menu-fit {
    padding: 0;
  }

  &.dropdown-menu-fit-bottom {
    padding-bottom: 0;
  }

  &.dropdown-menu-fit-top {
    padding-top: 0;
  }

  &.dropdown-menu-fit:not(.dropdown-menu-top-unround) {
    > div:first-of-type {
      @include kt-rounded {
        border-top-left-radius: $kt-border-radius;
        border-top-right-radius: $kt-border-radius;
      }
    }

    @include kt-rounded {
      form {
        border-top-left-radius: $kt-border-radius;
        border-top-right-radius: $kt-border-radius;
      }
    }
  }

  @include kt-rounded {
    border-radius: $kt-border-radius;

    &.dropdown-menu-top-unround {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:before,
  &:after {
    display: none !important;
  }

  > li > a,
  > .dropdown-item {
    outline: none !important;
    display: flex;
    flex-grow: 1;
    padding: 0.7rem 1.2rem;

    @include kt-icons-size(
      (
        lineawesome: 1.4rem,
        fontawesome: 1.4rem,
        flaticon: 1.3rem
      )
    );

    > i {
      margin-right: 0.75rem;
      color: kt-base-color(label, 2);

      &:before {
        line-height: 0;
        vertical-align: middle;
      }
    }
  }

  .dropdown-divider {
    border-top: 1px solid kt-base-color(grey, 2);
  }

  // RTL mode fix
  .dropdown-menu:not(.daterangepicker) {
    /*rtl:raw:
        left: auto !important;
        */
  }

  @each $name, $width in $kt-dropdown-menu-widths {
    &.dropdown-menu-#{$name} {
      width: $width;
    }
  }

  // Animations
  &.dropdown-menu-anim {
    animation: dropdown-menu-fade-in 0.3s ease 1,
      dropdown-menu-move-up 0.3s ease-out 1;
  }

  .dropup &,
  .dropdown-menu-anim-down {
    &.dropdown-menu-anim {
      animation: dropdown-menu-fade-in 0.3s ease 1,
        dropdown-menu-move-down 0.3s ease-out 1;
    }
  }

  // Alignment
  left: 0#{"/*rtl:ignore*/"};
  /*rtl:raw:
    right: auto;
    */

  // mobile mode
  @include kt-mobile {
    &.dropdown-menu-xl,
    &.dropdown-menu-lg,
    &.dropdown-menu-md {
      width: 300px;
    }
  }
}

// Dropdown toggle
.nav-link.dropdown-toggle,
.btn.dropdown-toggle {
  &:after {
    text-align: center;
    display: inline;
    border: 0;
    font-size: 0.6rem;
    line-height: 0;
    vertical-align: middle;
    position: relative;
    margin-left: 0.5rem#{"/*rtl:ignore*/"};
    opacity: 0.7;

    //@include kt-arrow-icon(down, true);
    @include kt-flaticon2-icon(kt-get($kt-action-bold-icons, down));
  }

  &.dropdown-toggle-split {
    &:after {
      right: 1px;
    }
  }
}

// Inline dropdown button
.dropdown.dropdown-inline {
  display: inline-block;
}

// Directions
.dropup {
  .nav-link.dropdown-toggle,
  .btn.dropdown-toggle {
    &:after {
      @include kt-flaticon2-icon(kt-get($kt-action-bold-icons, up));
    }
  }
}

.btn-group.dropright {
  .nav-link.dropdown-toggle,
  .btn.dropdown-toggle {
    &:before {
      display: none;
    }

    &:after {
      @include kt-flaticon2-icon(kt-get($kt-action-bold-icons, right));
    }
  }
}

.btn-group.dropleft {
  .nav-link.dropdown-toggle,
  .btn.dropdown-toggle {
    &:before {
      display: none;
    }

    &:after {
      @include kt-flaticon2-icon(kt-get($kt-action-bold-icons, left));
    }
  }
}

// Animations
@keyframes dropdown-menu-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dropdown-menu-move-down {
  from {
    margin-top: -10px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes dropdown-menu-move-up {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}
