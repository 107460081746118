//
// User Profile 4
//

.kt-widget {
  &.kt-widget--user-profile-4 {
    .kt-widget__head {
      margin-top: 1rem;

      .kt-widget__media {
        display: flex;
        justify-content: center;

        .kt-widget__img {
          max-width: 90px;
          max-height: 90px;

          @include kt-rounded {
            border-radius: 50%;
          }
        }

        .kt-widget__pic {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 90px;
          font-size: 1.5rem;

          @include kt-rounded {
            border-radius: 50%;
          }

          @each $name, $color in $kt-state-colors {
            &.kt-widget__pic--#{$name} {
              background: rgba(kt-get($color, base), 0.1);
            }
          }
        }
      }

      .kt-widget__content {
        .kt-widget__section {
          .kt-widget__username {
            text-align: center;
            display: block;
            padding: 0.8rem 0 0.6rem 0;
            font-size: 1.3rem;
            color: kt-base-color(label, 4);
            font-weight: 500;

            &:hover {
              color: kt-brand-color();
              transition: color 0.3s ease;
            }
          }

          .kt-widget__button {
            text-align: center;

            .btn {
              font-weight: 600;
              padding: 0.3rem 0.8rem;
            }
          }

          .kt-widget__action {
            text-align: center;
            margin-top: 2.3rem;

            > .btn-label-warning {
              &:hover {
                color: #fff;
              }
            }

            .btn:not(:first-child):not(:last-child) {
              margin: 0 1rem;
            }
          }
        }
      }
    }

    .kt-widget__body {
      margin-top: 2.5rem;

      .kt-widget__item {
        display: block;
        text-align: center;
        color: kt-base-color(label, 3);
        font-weight: 500;
        font-size: 1.1rem;
        padding: 0.9rem 0;
        margin: 0.4rem 0;

        &:hover {
          color: kt-brand-color();
          transition: color 0.3s ease;
          background: #edf0fc;
          cursor: pointer;

          @include kt-rounded {
            border-radius: $kt-border-radius;
          }
        }

        &.kt-widget__item--active {
          transition: color 0.3s ease;
          background: #edf0fc;
          color: kt-brand-color();

          @include kt-rounded {
            border-radius: $kt-border-radius;
          }
        }
      }
    }
  }
}

@include kt-mobile {
  .kt-widget {
    &.kt-widget--user-profile-4 {
      .kt-widget__head {
        .kt-widget__media {
          .kt-widget__img {
            max-width: 80px;
            max-height: 80px;
          }

          .kt-widget__pic {
            max-width: 80px;
            max-height: 80px;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
