//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//
.p_0 {
  padding: 0px !important;
}

.pr_0 {
  padding-right: 0px !important;
}
.pr_10 {
  padding-right: 10px !important;
}
.p_10 {
  padding: 10px !important;
}

.p_20 {
  padding: 20px !important;
}
.pt_10 {
  padding-top: 10px !important;
}
.pl_10 {
  padding-left: 10px !important;
}
.pl_20 {
  padding-left: 20px !important;
}
.pt_12{
  padding-top: 12px !important;
}

.m_0 {
  margin: 0px !important;
}
.mt_0 {
  margin-top: 0px;
}
.mt_5 {
  margin-top: 5px;
}
.mt_20 {
  margin-top: 20px;
}
.mb_0 {
  margin-bottom: 0px !important;
}
.mb_5 {
  margin-bottom: 5px;
}
.mt_10 {
  margin-top: 10px;
}
.mt_12{
  margin-top: 12px;
}
.mt_15 {
  margin-top: 15px;
}
.mb_10 {
  margin-bottom: 10px !important;
}
.mb_20 {
  margin-bottom: 20px;
}
.m_20_0 {
  margin: 20px 0px;
}

.mr_10 {
  margin-right: 10px !important;
}
.mr_20 {
  margin-right: 20px !important;
}

.mr_15 {
  margin-right: 15px;
}

.ml_10 {
  margin-left: 10px;
}
.ml_50 {
  margin-left: 50px;
}
.mt_50{
  margin-top: 50px;
}
.br_2 {
  border-radius: 2px;
}

.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 12px !important;
}
.font-16 {
  font-size: 16px;
}

.font-25 {
  font-size: 25px;
}
.font-35 {
  font-size: 35px;
}
.font-bold {
  font-weight: bold;
}
.font-700 {
  font-weight: 700;
}

.mh_20 {
  max-height: 20px;
}

// menu item display
.kt-menu__link-text{
  white-space: normal !important;
}
// menu item display


.my-custom-style {
  padding: 1rem 0.5rem;
  color: kt-brand-color();

  .my-custom-element {
    margin-left: 1rem;
  }
}
.justify_center{
  text-align: center;
}
.copywrite {
  position: absolute;
  bottom: 10px;
  font-size: 13px;
  font-weight: bolder;
  color: #fff;
  text-align: center;
  width: 100%;
}


.tooltip { 
  pointer-events: none !important; // to avoid flickering
}
@include kt-tablet-and-mobile {
  .my-custom-style {
    padding: 0.5rem 0.25rem;

    .my-custom-element {
      margin-left: 0.5rem;
    }
  }
}
//Pax timepicker component
.react-time-picker__inputGroup__input,.react-time-picker__inputGroup__divider{
  color: white !important;
}
.react-time-picker__inputGroup__input::placeholder{
  color:#c3c3c3 !important;

}

.react-time-picker__wrapper{
  border: 1px solid #c3c3c3 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none!important;
  padding-top: 5px !important;
}
//Pax timepicker component

// common - grid searchbar in table section 
.grid_searchbar{
  text-align: 'left';
  margin-top: '10px';
   width: "15px";
   .searchbar_label{
    .MuiFormLabel-root,.MuiInputBase-input{
    color: #000 !important;
   }
   #tableSearchIcon{
    color: #000 !important;
   }
  }
   
}
.use_table{
  .MuiTablePagination-select{
    //background-color: gray !important;
    color: black !important;
   }
   .MuiIconButton-root{
    color: inherit !important;
  }
  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

// common - grid searchbar in table section 


// start - support- tutorial section
.tutorial_list{
  padding: 50px;
  color:white !important;
  text-decoration: none;
  .list{
    a{
    color:white !important;
    font-size: 15px;
    &:hover{
      color:#fbbc05 !important;
    }
  }
  
}
}


//end - support tutorial section

//Map section - start

 .leaflet-tooltip {
  font-weight: bold;
  color:rgb(243, 22, 22);
  background: none;
  box-shadow: none;
  border: none;
}
.leaflet-tooltip-top:before, 
.leaflet-tooltip-bottom:before, 
.leaflet-tooltip-left:before, 
.leaflet-tooltip-right:before {
    border: none !important;
}

//Map section - End

// Report Section //

.MuiButtonGroup-contained{
  box-shadow: none!important  ;
}
.report_section {
  .report_collapse_section {
    background-color: #2c3d4f;
  }
  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiSelect-icon,
  .MuiIconButton-root {
    color: white;
  }
  .MuiInput-underline {
    &::before {
      border-bottom: 1px solid #999b9c;
    }
    &::after {
      border-bottom: 1px solid #999b9c;
    }
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #999b9c !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .MuiFormControlLabel-root {
    margin: 0px !important;
  }

  .btn-show_report {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c;
  }
  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover {
    border-color: #999b9c;
  }
  .e-multi-select-wrapper .e-delim-values .e-remain {
    color: white;
  }
  .e-multi-select-wrapper .e-delim-values {
    color: white;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: #fff;
  }

  input.e-dropdownbase::placeholder {
    color: white !important;
  }

  .mdl-demo {
    .makeStyles-formControl-2 {
      width: 100%;
      margin-top: 25px;
      .e-checkbox {
        margin-bottom: 0px;
      }
    }
  }
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickerDTTabs-tabs,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-thumb,
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #009684 !important;
}
.MuiPickersClockPointer-thumb {
  border-color: #009684 !important;
}
.MuiTypography-colorPrimary,
.MuiButton-textPrimary {
  color: #009684 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #ffc107 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ffc107 !important;
}
.asset_toggle {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-evenly;
  // margin-top: 25px !important;
  label {
    color: white;
  }
}

.MuiExpansionPanelDetails-root {
  display: initial !important;
  padding: 0px !important;
}

.pointer-none {
  pointer-events: none;
}

.kt-scrolltop {
  background: #009684;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #009684 !important;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #009684 !important;
}

.dashboard-quick-panel {
  z-index: 9999;
  position: fixed;
  -webkit-overflow-scrolling: touch;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  // -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  width: 570px !important;
  transition: right 0.3s ease, left 0.3s ease;
  right: 0px !important;
}
// .kt-quick-panel {
//   transition: none !important;
// }
// .kt-quick-panel {
//   background-color: #556270;
// }
// .dashboard-quick-panel {
//   background-color: #fff;
//   border-bottom-left-radius: 25px;
//   padding: 20px;
// }
// .kt-quick-panel.kt-quick-panel--on {
//   left: 220px !important;
//   transition: none !important;
// }
// .dashboard-quick-panel.dashboard-quick-panel--on {
//   right: 220px !important;
// }
.infofleet_live_panel {
  width: 225px !important;
}

// .kt-aside--minimize {
//   .kt-quick-panel.kt-quick-panel--on {
//     left: 70px !important;
//   }
// }
// .kt-aside--minimize {
//   .dashboard-quick-panel.dashboard-quick-panel--on {
//     right: 70px !important;
//   }
// }

// .kt-header__topbar-item--quick-panel {
//   position: fixed;
//   z-index: 1000;
//   background: #293847;
//   left: 0px;
//   top: 100px;
// }
// .kt-header__topbar-item--dashboard-panel {
//   position: fixed;
//   z-index: 1000;
//   background: #293847;
//   right: 0px;
//   top: 30px;
// }

// .kt-quick-panel--on .kt-header__topbar-item--quick-panel {
//   // transition: left 0.3s ease, right 0.3s ease;
//   // top:-25px;
//   left: 225px;
// }
.dashboard-quick-panel.dashboard-quick-panel--on {
  transition: right 0.3s ease, left 0.3s ease !important;
  right: 0 !important;
  left: initial !important;
  top: 64px !important;
  // height: 100vh;
}
.dashboard-quick-panel--on .kt-header__topbar-item--dashboard-panel {
  // transition: right 0.3s ease, left 0.3s ease;
  // top:-25px;
  right: 570px;
}

.liveview_label {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: white;
  border-radius: 10px;
}

.liveview_label_moving {
  background-color: #b0ff00;
  color: black;
}
.liveview_label_parked {
  background-color: #3d75f4;
  color: white;
}
.liveview_label_stopped {
  background-color: #ff862c;
  color: white;
}
.liveview_label_engineon {
  background-color: #ec0004;
  color: white;
}
.liveview_label_nosignal {
  background-color: #fdff00;
  color: black;
}
.liveview_label_tracked {
  background-color: #22448f;
}
.liveview_label_total {
  background-color: #139885;
}

.liveview_section {
  color: white;
  background: #293847;
  border-radius: 5px;
  padding: 6px;
  font-size: 12px;
  cursor: pointer;
  .lv_dd {
    justify-content: space-between;
  }
}

.liveview_search {
  background-color: #0abb87 !important;
  color: #ffe !important;
  top: 6px;
  border-radius: 0 !important;
  padding: 5px !important;
  min-width: fit-content !important;

  .la-search {
    transform: rotate(270deg);
  }
}
.liveview_close {
  background-color: #f44336 !important;
}
.AddGeofenceModal{
  overflow-y: auto;
  overflow-x: hidden;
}

#defaultTab {
  background: #556270;
}
.single_asset_details {
  width: 550px;
  min-height: 400px;
  position: absolute;
  bottom: 3%;
  z-index: 999;
  opacity: 1;
  right: 50px;
  .e-tab-header {
    background-color: #293847;
    .e-items {
      width: 100%;
      height: 45px;
      .e-item {
        min-width: 25%;
        float: left;
      }
    }

    .e-tab-text {
      font-size: 12px !important;
      text-transform: capitalize !important;
      color: white !important;
    }
  }
  .e-content {
    background: #556270;
    color: white !important;
    .e-item {
      // padding: 5px 15px;
      color: white !important;
    }

    .tab_section {
      padding: initial;
    }
    .fleet_body {
      padding: 5px 15px;
      img {
        height: 50px;
      }
    }
    .fleet_details {
      .row > div {
        padding: 0 !important;
      }
      white-space: normal;
      font-size: 12px !important;
      line-height: 2;
      &::after {
        content: "";
        border-right: 1px dashed #a39696;
        height: 90px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
    .status_details {
      white-space: nowrap;
      font-size: 12px !important;
      line-height: 2;
      &::after {
        content: "";
        border-right: 1px dashed #a39696;
        height: 100px;
        position: absolute;
        right: -10px;
        top: 5px;
      }
    }
    .fleet_footer_details {
      height: 80px;
      font-size: 11px !important;
      background-color: #293847;
      padding: 5px 5px;
      line-height: 17px;
    }
    .fleet_footer_carimg {
      margin: 9px 0px;
    }
    .fleet_footer {
      img {
        height: 35px;
      }
    }
  }
  .e-tab .e-tab-header .e-indicator {
    background: #009684;
    height: 4px;
    border-radius: 3px;
    width: 25%;
  }
}

.after_dash::after {
  content: "";
  border-right: 1px dashed #a39696;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.e-tab
  .e-tab-header
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item:last-child {
  padding-bottom: 20px !important;
}

.panel_search {
  background: #556270;
  color: white;
  padding: 15px;
  .panelsearch_item {
    background: #293847;
    width: 100%;
    float: left;
    padding: 5px 0px;
    text-align: center;
    font-size: 13px;
    white-space: nowrap;
  }
  .panelselecct_border {
    border: 2px solid #009684;
  }

  .MuiPaper-root {
    color: white !important;
    background: none !important;
  }
  .panelsearch_table {
    background-color: white !important;
  }
  .MuiExpansionPanelSummary-root {
    padding: 0 24px 0 0px !important;
    justify-content: flex-start !important;
  }
  .MuiExpansionPanelSummary-content {
    flex-grow: initial !important;
  }
  .asset_toggle {
    margin-top: 0px !important;
  }
  // .MuiFormControl-root {
  //   // width: 100%;
  // }
  .e-multi-select-wrapper .e-delim-values .e-remain {
    color: white;
    -webkit-text-fill-color: white;
  }
  .e-multi-select-wrapper .e-delim-values {
    color: white;
    -webkit-text-fill-color: white;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: #fff;
    -webkit-text-fill-color: white;
  }
  input.e-dropdownbase::placeholder {
    color: white !important;
  }
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c;
  }
  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover {
    border-color: #999b9c;
  }
  .panelsearch_radio {
    .MuiButtonBase-root {
      padding: 20px 0px 0px 0px;
    }
  }
  .date_radio {
    width: 6%;
    float: left;
  }
  .date_field {
    width: 100%;
  }
  .ps_qo_radio {
    width: 15%;
  }
  .ps_btn {
    position: relative;
    bottom: -20px;
    min-width: initial;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:before {
    border-bottom: 2px solid #999b9c;
  }
  .MuiInputBase-input,
  .MuiSvgIcon-root {
    color: #fff;
  }
  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiSelect-icon,
  .MuiIconButton-root,
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .MuiInput-underline::after {
    border-bottom: 1px solid #999b9c;
  }
  .MuiExpansionPanelSummary-content {
    margin: 0px !important;
  }
  .MuiExpansionPanelSummary-expandIcon {
    padding: 5px 0px 5px 0px;
  }
  .MuiExpansionPanelSummary-root {
    min-height: fit-content !important;
  }
  .MuiInput-underline {
    &::before {
      border-bottom: 1px solid #999b9c;
    }
    &::after {
      border-bottom: 1px solid #999b9c;
    }
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #999b9c !important;
  }
}

.infofleet_live_panel {
  input.e-dropdownbase::placeholder {
    color: white !important;
  }
  .e-multi-select-wrapper .e-delim-values .e-remain {
    color: white;
  }
  .e-multi-select-wrapper .e-delim-values {
    color: white;
    -webkit-text-fill-color: #fff;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: #fff;
  }
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c;
  }
  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover,
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled)
    .e-input-in-wrap:hover {
    border-color: #999b9c;
  }
}

.hide {
  display: none !important;
}
.show {
  display: block;
}

.single_asset_info {
  width: auto;
  // height: 35px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 15px;
  // color: white;
  font-weight: bold;

  .single_asset_info_closeicon {
    float: right;
    font-size: 18px;
    font-weight: bold;
  }
}

.graph_section {
  background-color: #2c3d4f;
  border-radius: 20px;
  padding: 20px;
  color: white;

  #dashboard_charts {
    #dashboard_charts_border {
      fill: #2c3d4f;
      height: auto;
    }
  }
  #dashboard_charts_svg {
    height: 250px;
  }
  .graph_info {
    color: white;
    align-items: center;
  }
  .graph-info-block {
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .graph-info-block-count {
      width: 80px;
      height: 50px;
      float: left;
      border-radius: 10px;
      font-size: 30px;
      font-weight: 400;
      color: white;
      text-align: center;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .graph-info-block-text {
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.graph_alert_section {
  margin-top: 15px;
  padding-right: 15px;
  .grapf_alert_section_bg {
    background-color: #2c3d4f;
    color: white;
    padding: 20px;
    border-radius: 20px;
    height: 300px;
    min-height: 300px;
  }
  .graph_alert_section_head {
    font-size: 20px;
    text-align: center;
  }
  .graph_alert_section_info {
    font-size: 16px;
    line-height: 2.2;
    margin-top: 10px;
  }
}

.graph_fleet_status {
  margin-top: 15px;
  padding-left: 0px;
  .graph_fleet_status_bg {
    background-color: #139885;
    color: white;
    padding: 20px 10px;
    border-radius: 20px;
    height: 300px;
    min-height: 300px;
  }
  .graph_fleet_section_head {
    font-size: 20px;
    text-align: center;
  }
  .graph_fleet_individual_area {
    background-color: white;
    color: black;
    height: 60px;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 10px;
    align-items: center;
    padding: 0px 5px;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: nowrap;
    .graph_fleet_individual_area_icon {
      height: 25px;
      border-radius: 10px;
      width: 30px;
      display: flex;
    }
    .graph_fleet_individual_area_count {
      width: 30px;
      height: 30px;
      background: #22448f;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      white-space: nowrap;
    }
  }
}
.graph_event_section {
  background-color: #1e1e2d;
  border-radius: 20px;
  height: 130px;
  padding: 20px;
  .graph_event_section_head {
    font-size: 20px;
    color: white;
  }
}

.leaflet-popup-content {
  width: 140px;
}
.map_popup {
  line-height: 1.5;
  span:first-child {
    width: 50px;
    float: left;
  }
}

.material_pagination_footer {
  .MuiSelect-icon,
  .MuiIconButton-root,
  .MuiInputBase-input {
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
  .Mui-disabled {
    .MuiSvgIcon-root {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

.status_search {
  .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon:before,
  .e-ddl.e-input-group .e-dropdownlist,
  .e-ddl.e-input-group input[readonly],
  .e-ddl.e-input-group input[readonly].e-input {
    color: white;
  }
  input.e-dropdownlist::placeholder {
    color: white !important;
  }
  .e-float-text,
  .e-float-input input:valid {
    color: white !important;
  }
  .e-float-input.e-control-wrapper input {
    border-color: #999b9c;
  }
  .location_text {
    width: 100%;
    .e-float-input {
      margin-top: 0px !important;
    }
  }
}

#share_location {
  line-height: 2;
  input {
    min-width: 200px;
    border: none;
    &::focus {
      border: none !important;
    }
  }
  .ps_btn {
    border: none;
    color: white;
    background-color: #009684;
  }
}

.operational_dashboard {
  background-color: #556270;
  padding: 15px;

  .individual_charts {
    background-color: white;
    border-radius: 10px;
    padding: 5px;

    .graph_title {
      color: #373d3f;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
  }
  h4 {
    color: white;
  }
}

.e-input-group,
.e-input-group.e-control-wrapper,
.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon:before {
  color: white;
}

input.e-dropdownlist::placeholder {
  color: white !important;
}

.user {
  background-color: #556270;
  padding: 15px;
  h4 {
    color: white;
  }
}

.historymap_close_btn {
  position: absolute !important;
  right: 75px;
  background-color: black !important;
  z-index: 999;
  opacity: 0.5;
  top: 10px;

  &:hover {
    opacity: 1;
  }

  svg {
    fill: white;
  }
}

.history_map {
  .leaflet-marker-pane {
    .leaflet-marker-icon {
      height: 75px !important;
    }
  }
}

.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label {
  color: white;
}

.MuiTable-root {
  .collapse_icon {
    color: #0a0a0a;
  }
  font-size: 1rem !important;
}

.custom-marker-player {
  background-size: 100% !important;
  margin-left: 0px;
  margin-top: -23px !important;
}

.geofence {
  .geofence_toggle_icon {
    position: absolute;
    right: 250px;
    top: 110px;
    width: 30px !important;
    min-width: auto !important;
    background-color: #1b1b28 !important;
    color: white !important;
    z-index: 999;
    border-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    padding: 5px !important;
  }
  .right_300 {
    right: 300px !important;
  }
  .right_0 {
    right: 0px !important;
  }

  #default-sidebar {
    width: 300px !important;
    background-color: #2c3d4f !important;
    color: white !important;
    padding: 25px;

    .togle_head {
      background-color: #139885;
      height: 70px;
      align-items: center;
      display: flex;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .jss4 {
    width: 100%;
  }
  .control-section {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
  }

  .sr-only {
    display: none;
  }

  #wrapper {
    display: block;
  }

  #close:hover,
  #close:active,
  #close:focus {
    background: white;
    color: black;
  }

  .right {
    float: right;
  }

  body {
    margin: 0;
  }

  .close-btn:hover {
    color: #fafafa;
  }

  .e-sidebar.e-right.e-close {
    right: 300px !important;
    display: block !important;
  }
  .e-sidebar.e-right.e-open {
    display: none !important;
  }

  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiCheckbox-root {
    color: white !important;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #999b9c !important;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c !important;
  }

  .e-input-group .e-input[readonly],
  .e-input-group.e-control-wrapper .e-input[readonly],
  .e-float-input input[readonly],
  .e-float-input.e-control-wrapper input[readonly],
  .e-float-input textarea[readonly],
  .e-float-input.e-control-wrapper textarea[readonly] {
    color: white;
  }

  .e-multi-select-wrapper .e-delim-values {
    -webkit-text-fill-color: white;
    color: white;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    color: white;
  }

  input.e-dropdownlist::placeholder {
    color: white !important;
  }
  input.e-dropdownbase::placeholder {
    color: white !important;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: white !important;
  }
}

.manage_settings {
  .ms_toggle_icon {
    position: absolute;
    right: 250px;
    top: 100px;
    width: 30px !important;
    min-width: auto !important;
    background-color: #1b1b28 !important;
    color: white !important;
    z-index: 999;
    border-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    padding: 5px !important;
  }
  .right_300 {
    right: 300px !important;
  }
  .right_0 {
    right: 0px !important;
  }

  #default-sidebar {
    width: 300px !important;
    background-color: #2c3d4f !important;
    color: white !important;
    padding: 25px;

    .togle_head {
      background-color: #139885;
      height: 70px;
      align-items: center;
      display: flex;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .jss4 {
    width: 100%;
  }
  .control-section {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
  }

  .sr-only {
    display: none;
  }

  #wrapper {
    display: block;
  }

  #close:hover,
  #close:active,
  #close:focus {
    background: white;
    color: black;
  }

  .right {
    float: right;
  }

  body {
    margin: 0;
  }

  .close-btn:hover {
    color: #fafafa;
  }

  .e-sidebar.e-right.e-close {
    right: 300px !important;
    display: block !important;
  }

  .e-sidebar.e-right.e-open {
    display: none;
  }

  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiCheckbox-root {
    color: white !important;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #999b9c !important;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c !important;
  }

  .e-input-group .e-input[readonly],
  .e-input-group.e-control-wrapper .e-input[readonly],
  .e-float-input input[readonly],
  .e-float-input.e-control-wrapper input[readonly],
  .e-float-input textarea[readonly],
  .e-float-input.e-control-wrapper textarea[readonly] {
    color: white;
  }

  .e-multi-select-wrapper .e-delim-values {
    -webkit-text-fill-color: white;
    color: white;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    color: white;
  }

  input.e-dropdownlist::placeholder {
    color: white !important;
  }
  input.e-dropdownbase::placeholder {
    color: white !important;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: white !important;
  }
}
.mb_15 {
  margin-bottom: 15px !important;
}

.mb__10 {
  margin-bottom: -10px !important;
}

.error-msg {
  color: #e7e308;
}

.search_grl_div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.geofence {
  .MuiButton-root {
    background-color: #139885;
    color: white !important;
  }
}

.geo_closebtn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.flex_div {
  display: flex;
}

.fcc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.df{
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit_geofence {
  .editgeo_search_btn {
    .MuiButton-root {
      border-radius: 0px !important;
      padding: 12px 10px !important;
      min-width: initial !important;
      .MuiButton-label {
        font-size: 20px;
      }
    }
  }
}

.infofleet_alert {
  position: absolute;
  z-index: 9999;
  // width: 100%;
  border-radius: 0px !important;
}

.report_infofleet_alert {
  position: absolute;
  z-index: 9999;
  // width: 100%;
  border-radius: 0px !important;
  top:50px;
  left:20px;
  height:50px;
}
.mrpreport_infofleet_alert {
  position: absolute;
  z-index: 9999;
  // width: 100%;
  border-radius: 0px !important;
  top:50px;
}

.marker_setion {
  .MuiFormControlLabel-root {
    margin-left: initial !important;
    margin-right: initial !important;
    .MuiIconButton-root {
      padding: 9px 3px 9px 9px !important;
    }
  }
}

.selectedmarkerimg {
  background-color: white;
  padding: 3px;
  margin-left: 10px;
}

.savedpoiicon {
  margin-right: 10px;
}

.url_flex_div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: white;
  }
}

.scolumn_delete_icon {
  margin-top: 18px;
  fill: red !important;
  cursor: pointer;
}

.scolumn_check {
  margin: 3px !important;
}

.scolumn_add_btn {
  cursor: pointer;
}

// .main_section {
//   // padding: 15px;
// }

.manage_settings {
  background: #556270;
  color: white;
  padding: 15px;
  .ms_item {
    background: #556270;
    width: auto;
    float: left;
    padding: 5px 0px;
    text-align: center;
    font-size: 14px;
    margin: 5px 5px 5px 0px;
  }
  .ms_border {
    border: 2px solid #009684;
  }

  .MuiChip-root {
    color: white !important;
  }
  .MuiChip-clickable:hover,
  .MuiChip-clickable:focus {
    background-color: rgb(206, 206, 206);
    color: black !important;
  }

  .infofleet_alert {
    bottom: 10px;
  }

  .MuiFormControl-marginNormal {
    margin: 0px !important;
  }

  .MuiSelect-icon {
    fill: #fff !important;
  }
  .MuiInputAdornment-root {
    .MuiSvgIcon-root {
      fill: #fff !important;
    }
  }

  .MuiAutocomplete-clearIndicator,
  .MuiAutocomplete-popupIndicator {
    fill: #fff !important;
    color: #ffffff8a !important;
  }
}

.mrp_section{
  .MuiFormHelperText-root {
    color: #e7e308 !important;
  }
  padding: 20px;
  
}

.manage_account {
  background: #556270;
  color: white;
  padding: 15px;
  .ms_item {
    background: #556270;
    width: auto;
    float: left;
    padding: 5px 0px;
    text-align: center;
    font-size: 14px;
    margin: 5px 5px 5px 0px;
  }
  .ms_border {
    border: 2px solid #009684;
  }

  .MuiChip-root {
    color: white !important;
  }
  .MuiChip-clickable:hover,
  .MuiChip-clickable:focus {
    background-color: rgb(206, 206, 206);
    color: #fff !important;
  }

  .infofleet_alert {
    bottom: 10px;
  }

  .MuiFormControl-marginNormal {
    margin: 0px !important;
  }

  .MuiSelect-icon {
    fill: #fff !important;
  }
  .MuiInputAdornment-root {
    .MuiSvgIcon-root {
      fill: #fff !important;
    }
  }

  .MuiAutocomplete-clearIndicator,
  .MuiAutocomplete-popupIndicator {
    fill: #fff !important;
    color: #ffffff8a !important;
  }

  .ms_toggle_icon {
    position: absolute;
    right: 250px;
    top: 100px;
    width: 30px !important;
    min-width: auto !important;
    background-color: #1b1b28 !important;
    color: white !important;
    z-index: 999;
    border-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    padding: 5px !important;
  }
  .right_300 {
    right: 300px !important;
  }
  .right_0 {
    right: 0px !important;
  }

  #default-sidebar {
    width: 300px !important;
    background-color: #2c3d4f !important;
    color: white !important;
    padding: 25px;

    .togle_head {
      background-color: #139885;
      height: 70px;
      align-items: center;
      display: flex;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .jss4 {
    width: 100%;
  }
  .control-section {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
  }

  .sr-only {
    display: none;
  }

  #wrapper {
    display: block;
  }

  #close:hover,
  #close:active,
  #close:focus {
    background: white;
    color: black;
  }

  .right {
    float: right;
  }

  body {
    margin: 0;
  }

  .close-btn:hover {
    color: #fafafa;
  }

  .e-sidebar.e-right.e-close {
    right: 300px !important;
    display: block !important;
  }
  .e-sidebar.e-right.e-open {
    display: none;
  }

  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiCheckbox-root {
    color: white !important;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #999b9c !important;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c !important;
  }

  .e-input-group .e-input[readonly],
  .e-input-group.e-control-wrapper .e-input[readonly],
  .e-float-input input[readonly],
  .e-float-input.e-control-wrapper input[readonly],
  .e-float-input textarea[readonly],
  .e-float-input.e-control-wrapper textarea[readonly] {
    color: white;
  }

  .e-multi-select-wrapper .e-delim-values {
    -webkit-text-fill-color: white;
    color: white;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    color: white;
  }

  input.e-dropdownlist::placeholder {
    color: white !important;
  }
  input.e-dropdownbase::placeholder {
    color: white !important;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: white !important;
  }

  .MuiFormHelperText-root {
    color: #e7e308 !important;
  }
}
.edit_asset {
  .MuiFormControl-marginNormal {
    margin: 0px !important;
  }
}

.btn_group {
  display: flex;
  align-items: center;
}

.dn_checkbox {
  .MuiFormControlLabel-labelPlacementStart {
    margin: 0;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.disableDiv {
  position: relative;
  &::after {
    cursor: not-allowed;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}

.report_section {
  background: #556270;
  color: white;
  padding: 15px;

  .ms_toggle_icon {
    position: absolute;
    right: 250px;
    top: 100px;
    width: 30px !important;
    min-width: auto !important;
    background-color: #1b1b28 !important;
    color: white !important;
    z-index: 999;
    border-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    padding: 5px !important;
  }
  .right_300 {
    right: 300px !important;
  }
  .right_0 {
    right: 0px !important;
  }

  #default-sidebar {
    width: 300px !important;
    background-color: #2c3d4f !important;
    color: white !important;
    padding: 25px;
    overflow-x: hidden;

    .togle_head {
      background-color: #139885;
      height: 70px;
      align-items: center;
      display: flex;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  
  .jss4 {
    width: 100%;
  }
  .control-section {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
  }

  .sr-only {
    display: none;
  }

  #wrapper {
    display: block;
  }

  #close:hover,
  #close:active,
  #close:focus {
    background: white;
    color: black;
  }

  .right {
    float: right;
  }

  body {
    margin: 0;
  }

  .close-btn:hover {
    color: #fafafa;
  }

  .e-sidebar.e-right.e-close {
    right: 300px !important;
    display: block !important;
  }
  .e-sidebar.e-right.e-open {
    display: none;
  }

  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiCheckbox-root {
    color: white !important;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #999b9c !important;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c !important;
  }

  .e-input-group .e-input[readonly],
  .e-input-group.e-control-wrapper .e-input[readonly],
  .e-float-input input[readonly],
  .e-float-input.e-control-wrapper input[readonly],
  .e-float-input textarea[readonly],
  .e-float-input.e-control-wrapper textarea[readonly] {
    color: white;
  }

  .e-multi-select-wrapper .e-delim-values {
    -webkit-text-fill-color: white;
    color: white;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    color: white;
  }

  input.e-dropdownlist::placeholder {
    color: white !important;
  }
  input.e-dropdownbase::placeholder {
    color: white !important;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: white !important;
  }

  .MuiSelect-icon {
    fill: white;
  }

  .chart_checkbox {
    flex-direction: inherit !important;
  }
  .infofleet_alert {
    bottom: 10px;
  }
}

.alert_actions {
  white-space: nowrap !important;

  .alert_edit {
    color: #139885;
    cursor: pointer;
  }
  .alert_delete {
    color: #f44336;
    cursor: pointer;
  }
}

.MuiTypography-colorTextSecondary {
  color: #ffffff8a !important;
}

.LivePanelSidebar {
  .ms_toggle_icon {
    position: absolute;
    left: 0px;
    top: 100px;
    width: 30px !important;
    min-width: auto !important;
    background-color: #1b1b28 !important;
    color: white !important;
    z-index: 999;
    border-radius: 0px !important;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    padding: 5px !important;

    img {
      width: 25px;
    }
  }
  .ms_toggle_icon1 {
    position: absolute;
    left: 0px;
    top: 200px;
    width: 30px !important;
    min-width: auto !important;
    background-color: #1b1b28 !important;
    color: white !important;
    z-index: 999;
    border-radius: 0px !important;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    padding: 5px !important;

    img {
      width: 25px;
    }
  }
  .right_300 {
    right: 300px !important;
  }
  .right_0 {
    left: 0px !important;
  }

  #default-sidebar {
    width: 225px !important;
    background-color: #556270 !important;
    color: white !important;
    top: 64px;
    // padding: 25px;

    .togle_head {
      background-color: #139885;
      height: 70px;
      align-items: center;
      display: flex;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  #default-sidebar1 {
    width: 350px !important;
    background-color: #2c3d4f !important;
    color: white !important;
    padding: 25px;

    .togle_head {
      background-color: #139885;
      height: 70px;
      align-items: center;
      display: flex;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
      cursor: pointer;
    }
   
  }
  .jss4 {
    width: 100%;
  }
  .control-section {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
  }

  .e-content-animation {
    left: 0px !important;
    position: fixed !important;
  }
  .liveview_search_div {
    display: flex;
    align-items: baseline;
  }

  .e-multi-select-wrapper .e-delim-values .e-remain {
    color: white;
    -webkit-text-fill-color: white;
  }
  .e-multi-select-wrapper .e-delim-values {
    color: white;
    -webkit-text-fill-color: white;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: #fff;
    -webkit-text-fill-color: white;
  }
  
  input.e-dropdownbase::placeholder {
    color: white !important;
  }


  .MuiFormLabel-root,
  .MuiInputBase-root
   {
    color: white !important;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #999b9c !important;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border-color: #999b9c !important;
  }

  .e-input-group .e-input[readonly],
  .e-input-group.e-control-wrapper .e-input[readonly],
  .e-float-input input[readonly],
  .e-float-input.e-control-wrapper input[readonly],
  .e-float-input textarea[readonly],
  .e-float-input.e-control-wrapper textarea[readonly] {
    color: white;
  }

  .e-multi-select-wrapper .e-delim-values {
    -webkit-text-fill-color: white;
    color: white;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    color: white;
  }

  input.e-dropdownlist::placeholder {
    color: white !important;
  }
  input.e-dropdownbase::placeholder {
    color: white !important;
  }
  .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    color: white !important;
  }

  .leaflet-right {
    top: 70px !important;
  }

  // .leaflet-map-pane {
  //   .leaflet-marker-pane {
  //     img {
  //       width: 40px !important;
  //       height: 40px !important;
  //     }
  //   }
  // }
}
.GeofencePopup .MuiCheckbox-root{
  color: white !important
}

.leaflet-top,
.leaflet-bottom {
  z-index: 999 !important;
}
.leaflet-bottom .leaflet-control{
  margin-bottom: 30px!important;
}
.leaflet-top .leaflet-control{
  margin-top: 50px!important;
}

.vp_row {
  margin-bottom: 10px;
  align-items: center;
}

.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-filter,
.e-ddl.e-popup.e-multi-select-list-wrapper
  .e-filter-parent
  .e-input-group.e-input-focus
  .e-input-filter {
  color: black !important;
}

.reset_pwd {
  .MuiFormHelperText-root {
    color: red !important;
    font-size: 1rem !important;
  }
}

.inside_ms_item {
  .ms_item {
    background: #2c3d4f !important;
  }
  .MuiChip-root {
    color: white !important;
  }
}

.flex_end {
  align-items: flex-end !important;
}
.flex_center {
  align-items: center !important;
}
.justify_sb {
  justify-content: space-between;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.TT_C {
  text-transform: capitalize !important;
}

.journeyreport_radio {
  flex-direction: inherit !important;
  justify-content: space-around;
}

.office_hrs {
  .MuiFormControlLabel-root {
    margin-right: auto !important;
  }
  .MuiIconButton-root {
    flex: inherit !important;
  }
}

.MuiIconButton-root {
  flex: initial !important;
}

.MuiBackdrop-root {
  background-color: #000000cc !important;
  font-size: 2rem;
}

.transactionlog_btngroup {
  display: initial !important;
  box-shadow: none !important;
}

.share_location {
  .MuiFormLabel-root {
    color: #ffffff !important;
  }
  .MuiFormLabel-root.Mui-focused,
  .MuiInputBase-input {
    color: white;
  }
  .MuiFormHelperText-root {
    color: #ffffff8a;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #ffffff6b;
  }
}

.sharelocation {
  position: absolute;
  z-index: 9999;
  border-radius: 0 !important;
  bottom: 10px;
  margin-left: 15px !important;
  width: auto;
}

.dash_sett_bg {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.MuiTablePagination-spacer {
  flex: initial !important;
}

.panelsearch_table_section {
  // max-height: 50vh;

  .MuiTableContainer-root {
    height: 51vh;
    overflow-y: scroll;
  }
  .MuiIconButton-root{
    color:#000;
  }
}

.Moving_text {
  color: #b0ff00 !important;
  font-weight: bold !important;
}

.Stopped_text {
  color: #fe852c !important;
  font-weight: bold !important;
}
.Engineon_text {
  color: #ec0004 !important;
  font-weight: bold !important;
}

.Parked_text {
  color: #3d75f4 !important;
  font-weight: bold !important;
}

.Nosignal_text {
  color: #ffe500 !important;
  font-weight: bold !important;
}

.MuiTableCell-root {
  font-size: 1rem !important;
  white-space: nowrap;
  padding: 10px !important;
  user-select: none;
}

.kt-notification__item {
  img {
    width: 20px;
    margin-right: 5px;
  }
}

.driver_section {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.kt-notification .kt-notification__item {
  padding: 1rem !important;
  border-radius: 4px;
  margin-bottom: 3px;
  border: 1px solid #c4c7cc;
}
.kt-notification .kt-notification__item:last-child {
  border: 1px solid #c4c7cc;
}
.kt-quick-panel .kt-quick-panel__content {
  padding: 1rem !important;
}

.kt-notification .kt-notification__item:after {
  display: none;
}

.unread_notification {
  font-size: 1.7rem;
}
.unreadimg {
  width: 50px;
  margin-right: 10px;
}

.unread_noti_div {
  display: flex;
  align-items: center;
}

.report_table {
  height: 86vh;
}

.e-dropdownbase.e-content {
  overflow-x: scroll !important;
}

.login_btn_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.kt-login.kt-login--v1
  .kt-login__wrapper
  .kt-login__body
  .kt-login__form
  .kt-login__actions {
  margin: 1rem 0 !important;
}

.kt-menu__link-icon {
  width: 30px;
}

.leaflet-bottom .leaflet-control-attribution a {
  display: none;
}

.top_fixed_section {
  background: #556270;
  padding: 10px 10px;
  position: fixed;
  width: 100%;
  z-index: 999;

  .e-multi-select-wrapper input[type="text"] {
    font-style: italic;
  }
}

.liveviewicons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .topbar_count {
    margin: 0px 5px;
  }
  .liveviewicons_row_div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    flex-wrap: nowrap;
  }
  .lv_move {
    .MuiCheckbox-root {
      color: #b0ff00 !important;
    }
  }
  .lv_park {
    .MuiCheckbox-root {
      color: #3d75f4 !important;
    }
  }
  .lv_stop {
    .MuiCheckbox-root {
      color: #ff862c !important;
    }
  }
  .lv_nosignal {
    .MuiCheckbox-root {
      color: #fdff00 !important;
    }
  }
  .lv_engineOn {
    .MuiCheckbox-root {
      color: #dd191d !important;
    }
  }
  .liveviewicons_section {
    font-size: 1.5rem;
    color: white;
    border-right: 2px dashed #c3c3c3;
    padding: 0 10px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .liveviewicons_section_label {
      margin-right: 0px !important;
      margin-bottom: 0px !important;
      .liveviewicons_section_label_base {
        padding: 5px !important;
      }
    }
  }
  img {
    height: 30px;
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
}
// .icon_div_section {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

.timeline_graph {
  .kt-notification-v2__item-title {
    color: white !important;
    font-size: 1rem !important;
  }
}

// Fleet Section

.fleet_section {
  .asset_toggle {
    .MuiSwitch-switchBase {
      width: fit-content !important;
    }
  }
}

.bs-tooltip-top {
  z-index: 99999 !important;
}

#react-modal-image-img {
  background-color: white !important;
  height: 300px;
}

.asset_drop_area {
  .error-msg {
    position: absolute;
  }
}

.flex-direction-initial {
  flex-direction: initial !important;
}

.fleet_edit_form {
  width: 40% !important;
  height: 92vh;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1000;
  background: #556170;
  padding: 15px;
  border: 5px solid #737777;
}

.leaflet-popup-close-button {
  display: none !important;
}

// .dialog-target{
//   min-height: 350px;
// }

.leaflet-popup-content-wrapper {
  padding: 0px !important;
  border-radius: 12px 12px 0px 0px;
  .leaflet-popup-content {
    width: 220px !important;
    margin: 0px 10px 0px 10px !important;
    line-height: 2 !important;

    .left-side {
      font-weight: bold;
      color: #35c2ff;
      border-right: 1px solid #e4e4e4;
    }

    .bb {
      border-bottom: 1px solid #e4e4e4;
    }
    .bt {
      border-top: 1px solid #e4e4e4;
    }
  }
}

.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon {
  border-radius: 4px 0px 0px 4px;
  border-right: 1px solid #bdbdbd;
  height: 35px;
}

.homepage_companyname {
  align-items: center;
  display: flex;
  padding: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: #0042ff;
  font-size: 14px;
}

.MuiTable-root .Mui-selected {
  background-color: #fff38e !important;
}

.selectedvehiclepanel_moving {
  border: 2px solid #b0ff00;
}

.selectedvehiclepanel_stopped {
  border: 2px solid #ff862c;
}

.selectedvehiclepanel_engineon {
  border: 2px solid #ec0004;
  white-space: nowrap;
}

.selectedvehiclepanel_parked {
  border: 2px solid #3d75f4;
}

.selectedvehiclepanel_nosignal {
  border: 2px solid #fdff00;
}

.custommaptooltip {
  .plateno_row {
    background: #35c2ff;
    color: white;
    padding: 2px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    font-size: 14px;
    .left-side {
      color: white;
    }
  }
}

.applyicon_lv {
  background: #009688;
  padding: 5px;
  color: white;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
}

.top_fixed_section {
  .MuiFormLabel-root {
    color: #ffffff !important;
    font-style: italic !important;
    top: -5px;
  }
  .MuiInputLabel-shrink {
    top: auto !important;
  }
  .MuiFormLabel-root.Mui-focused,
  .MuiInputBase-input {
    color: white;
  }
  .MuiFormHelperText-root {
    color: #ffffff8a;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #ffffff6b;
  }
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left;
  line-height: 18px;
  float: left;
  color: #000;
  display: flex;
  background: #ffffff;
  padding: 5px;
  font-size: 14px;
}

.speed_legend{
 margin-left: 280px !important;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 1;
}

.speed1 {
  background-color: #ab47bc;
}
.speed2 {
  background-color: #6a1b9a;
}
.speed3 {
  background-color: #03a9f4;
}
.speed4 {
  background-color: #01579b;
}
.speed5 {
  background-color: #dd191d;
}
.speed6 {
  background-color: #8b0000;
}

.normaltrack {
  background-color: #669df6;
}
.violatedtrack {
  background-color: #dd191d;
}

.vl {
  i {
    opacity: 1 !important;
  }
}

.single_asset_details {
  .timeline_chart {
    .e-content {
      color: initial !important;
    }
    // .apexcharts-yaxis-label,
    // .apexcharts-yaxis .apexcharts-xaxis-inversed {
    //   display: none;
    // }
    .apexcharts-menu-icon {
      display: none;
    }
    .apexcharts-xaxis-label,
    .apexcharts-yaxis-label,
    .apexcharts-zoom-icon svg,
    .apexcharts-zoomin-icon svg,
    .apexcharts-zoomout-icon svg,
    .apexcharts-reset-icon svg {
      fill: white !important;
    }
    .apexcharts-tooltip-rangebar {
      color: black !important;
    }
    .apexcharts-canvas {
      svg {
        path {
          stroke: none !important;
        }
      }
    }
    // .apexcharts-grid,
    // .apexcharts-xaxis,
    // .apexcharts-yaxis,.apexcharts-toolbar {
    //   display: none;
    // }
    //   position: relative;
    //   .apexcharts-toolbar,
    //   .apexcharts-grid,
    //   .apexcharts-xaxis,
    //   .apexcharts-yaxis {
    //     display: none;
    //   }
  }
}

.timeline_graph_legend {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .moving_box {
    width: 20px;
    height: 20px;
    background: #99ff33;
    float: left;
    margin-right: 10px;
  }
  .parked_box {
    width: 20px;
    height: 20px;
    background: #157dec;
    float: left;
    margin-right: 10px;
  }
  .stoped_box {
    width: 20px;
    height: 20px;
    background: #ff862c;
    float: left;
    margin-right: 10px;
  }
  .idleengineon_box {
    width: 20px;
    height: 20px;
    background: #ec0004;
    float: left;
    margin-right: 10px;
  }
}

.focustrack_div {
  .MuiSvgIcon-root {
    color: white !important;
  }
  .MuiTypography-root {
    font-weight: bold;
  }
}

.live_view_tv {
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 150px;
  background: #556270;
  color: white;
  padding: 10px;
  border: none;
  img {
    width: 20px;
  }
}

.fullscreen-enabled {
  .leaflet-container {
    height: 100% !important;
  }
}

.alertmarquee_div {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background-color: white;
}

.alertmarquee {
  height: 100vh;
  width: max-content;
  // position: absolute;
  overflow-x: hidden;
  // top: 0;
  // left: 0;
  -webkit-animation: marquee 75s linear infinite;
  animation: marquee 75s linear infinite;
  font-size: 16px;
  font-weight: bold;
  color: red;
  padding: 15px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.track_section {
  span {
    color: white;
  }
  .Mui-disabled {
    span {
      color: white;
    }
  }
}

.notify_div {
  position: relative;
  div {
    position: initial !important;
  }
  .notify_count {
    top: -5px !important;
    right: -7px !important;
  }
}

.report_info_bck_btn {
  right: 330px;
  position: absolute !important;
  top: 15px;
}

.infoselected_tick {
  fill: #139885 !important;
  position: absolute;
  top: 16px;
  right: 20px;
}

.cp {
  cursor: pointer;
}

.ad_tinfo {
  font-size: 1rem !important;
}

.fromhr_timeline {
  position: absolute;
  top: 30px;
  left: 15px;
}

.tohr_timeline {
  position: absolute;
  right: 85px;
  top: 30px;
}

// .tg {
//   .apexcharts-canvas {
//     svg {
//       path {
//         stroke: none !important;
//       }
//     }
//   }
// }

.infofleet_alert {
  bottom: 10px;
}

.tabpanelsection {
  overflow-y: hidden;
  overflow-x: hidden;
}

.info_div {
  min-height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fleet_div {
  min-height: 200px;
}
.io_div {
  min-height: 135px;
}

.event_div {
  min-height: 180px;
}

.cluster_div {
  display: flex;
  align-items: center;
  width: initial !important;
}

.zoomlock_active {
  color: #023ffb !important;
  font-weight: 500 !important;
}

.mobileapp_title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}

.login_app_area {
  .appimg,
  .playimg {
    width: 50%;
  }
  .QRimg {
    width: 100px;
  }
}

.marker-cluster {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}
.marker-cluster div {
  width: 40px !important;
  height: 40px !important;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 25px !important;
  font: 20px "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: bold !important;
  color: #000 !important;
}

.marker-cluster span {
  line-height: 2;
}

// Dot Loader
#dotloader.background {
  position: absolute;
  top: 60px;
  bottom: 62px;
  left: 0;
  right: 0;
  background: rgba(245, 245, 245, 0.9);
  z-index: 99999;
}

#dotloader .dots {
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -50px;
  z-index: 999999;
}
#dotloader .dots span {
  -webkit-transition: all 6000ms ease;
  transition: all 6000ms ease;
  background: rgba(198, 12, 48, 0.5);
  height: 15px;
  width: 15px;
  margin: 0 2px 0 0;
  display: inline-block;
  border-radius: 50%;
  animation: wave 2s ease infinite;
  -webkit-animation: wave 2s ease infinite;
}

#dotloader .dots span:nth-child(1) {
  animation-delay: 0;
  -webkit-animation-delay: 0;
}
#dotloader .dots span:nth-child(2) {
  animation-delay: 100ms;
  -webkit-animation-delay: 100ms;
}
#dotloader .dots span:nth-child(3) {
  animation-delay: 200ms;
  -webkit-animation-delay: 200ms;
}

@-webkit-keyframes wave {
  0%,
  40%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: rgba(198, 12, 48, 0.5);
  }
  10% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    background-color: rgba(198, 12, 48, 1);
  }
}

@keyframes wave {
  0%,
  40%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: rgba(198, 12, 48, 0.5);
  }
  10% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    background-color: rgba(198, 12, 48, 1);
  }
}

// Dot Loader

.focusloader {
  width: 20px !important;
  height: 20px !important;
  color: white !important;
  left: -20px;
  position: relative;
  top: 5px;
  margin: 3px !important;
}

.share_location_btn {
  display: flex;
  justify-content: space-between;
  img {
    width: 32px !important;
    height: 32px !important;
    cursor: pointer;
  }
}

.vehicleinfo_info {
  display: flex;
  justify-content: center;
  padding: 10px;

  .infospan {
    font-size: 1.1rem;
    position: relative;

    &::after {
      content: "";
      border: 1px solid #009684;
      height: 100px;
      position: absolute;
      right: 50%;
      top: -25px;
      width: 2px;
      transform: rotate(90deg);
    }
  }
}

.nowrap {
  white-space: nowrap !important;
}

.breakword {
  word-break: break-word;
}

.support_section {
  .MuiCardContent-root:last-child {
    padding-bottom: 16px !important;
  }
}

.panelsearch_mapsection {
  position: relative;

  .whatsapp_sharebtn {
    position: absolute;
    bottom: 10px;
    z-index: 999;
    margin-left: 100px;
    .MuiButton-root {
      background-color: #fff38e !important;
      color: black !important;
    }
  }
}

// Area Name Filter in Live View

.auto_suggest_div {
  .e-input::placeholder {
    color: white !important;
  }

  .areaname_search {
    margin-bottom: 0px;
    margin-top: 13px;
  }
}
.e-dropdownbase .e-list-item .e-highlight {
  color: #009688 !important;
}

// Area Name Filter in Live View

.viewdriver_tables {
  max-height: 75vh;
  overflow: auto;
}

.pulsing-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #4285f4;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fbbc05;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

// List View on Live View
.ListViewIcon {
  cursor: pointer;
  left: 50%;
  bottom: 30px;
  min-width: auto !important;
  background-color: #1b1b28 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 1px !important;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  z-index: 999;
  .ListViewIcon_svg {
    width: 28px;
    height: 28px;
  }
}
.PanelSearchListViewIcon {
  cursor: pointer;
  left: 40%;
  bottom: 30px;
  min-width: auto !important;
  background-color: #1b1b28 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 1px !important;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  z-index: 999;
  .ListViewIcon_svg {
    width: 28px;
    height: 28px;
  }
}
.hideGridIcon{
  display:none;
}
.List_View {
  position: absolute;
  height: 400px;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  bottom: 0px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.2s;
  animation-name: animatetop;
  animation-duration: 0.2s;

  .List_view_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .listview_cancel_btn {
      cursor: pointer;
    }
  }
  .List_table_section {
    padding: 0px 10px;
    .MuiTableContainer-root {
      max-height: 345px;
      overflow: auto;
    }
  }
}

.PanelSearch_List_View {
  position: relative;
  
  background-color: #fff;
  

  .List_view_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .listview_cancel_btn {
      cursor: pointer;
      color:#000;
    }
  }
 
  .List_table_section {
    padding: 0px 10px;
    .MuiTableContainer-root {
      max-height: 250px;
      overflow: auto;
    }
  }
}

// .LV_ani_on {
//   -webkit-animation-name: animatetop;
//   -webkit-animation-duration: 0.2s;
//   animation-name: animatetop;
//   animation-duration: 0.2s;
// }

@-webkit-keyframes animatetop {
  from {
    bottom: 0px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
// @keyframes animatetop {
//   from {
//     bottom: -50px;
//     opacity: 0;
//   }
//   to {
//     bottom: 0;
//     opacity: 1;
//   }
// }

// .LV_ani_off{
//   -webkit-animation-name: animatebottom;
//   -webkit-animation-duration: 0.15s;
//   animation-name: animatebottom;
//   animation-duration: 0.15s;
// }

// @-webkit-keyframes animatebottom {
//   from {
//     bottom: 0px;
//     opacity: 0;
//   }
//   to {
//     bottom: -50px;
//     opacity: 1;
//   }
// }
// @keyframes animatebottom {
//   from {
//     bottom: 0px;
//     opacity: 0;
//   }
//   to {
//     bottom: -50px;
//     opacity: 1;
//   }
// }

// List View on Live View

// Media Quries for Tablet View

/* Portrait and Landscape */

.alldrivers {
  display: flex;
  align-items: center;
}

.fullscreen_status_icons {
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 1111;
  background: #ffffff;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #9e9e9e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .logoimg {
    width: 100px;
  }
  .liveviewicons_section {
    font-size: 2rem;
  }
  .fullscreen_status_icons_area {
    display: flex;
    align-items: center;
    img {
      width: 80px;
    }

    .topbar_count{
      font-size: 3rem;
      font-family: 'Bebas Neue', cursive;
      color: black;
    font-weight: bold;
    }
    .MuiCheckbox-root{
      color: #000 !important;
    }
  }
  .fullscreen_time {
    font-size: 1.75rem;
    font-weight: bold;

  }
}



.togglehistory_icon {
  .MuiSvgIcon-root{
    fill:#000 !important
  }
}

// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .kt-grid--hor-tablet-and-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
  .kt-content,
  .kt-container {
    padding: initial !important;
  }
  .hide_sm {
    display: none;
  }
}
